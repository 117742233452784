<template>
<div class="scoreFound">
    <div class="top"><Search class="search" v-model="text" @search="searchScore"/><Classify class="classify" v-model="typeId" @change="classify"/></div>
    <ScoreContent :scores="pageData.data"><i class="iconfont icon-all">&nbsp;</i>全部乐谱</ScoreContent>
    <div class="pc"><PageController @change="change" v-model="pageData" class="ctl"/></div>
</div>
</template>

<script>
import ScoreContent from '../home/components/scoreContent.vue'
import PageController from './components/pageController.vue'
import Search from './components/search.vue'
import Classify from './components/classify.vue'
import Message from '@/components/library/Message.js'
//import {getAllScores,queryScoreResPage,searchScoreResPage} from '../../api/score'
import {ref} from 'vue'
import { ScorePower,ScoreStatus } from '../../enum'
import { scores } from '../../assets/images/score'
import axios from "../../utils/request"
export default {
    name:'ScoreFound',
    components:{
        ScoreContent,
        PageController,
        Classify,
        Search
    },
    setup(){
        //const temp=ref(0);
        //const scs=ref([]);
        const pageData=ref(null)
        let temp={
            pageNum:1,
            totalNum:1,
            data:[]
        }
        pageData.value=temp;
        pageData.value.data=scores;
        const getPageScoreList=(pageNum,typeId)=>{
            axios.post("/score/getPageScoreList",{
                pageSize:8,
                pageNum:pageNum,
                model:{
                    power:ScorePower.PUBLIC.value,
                    status:ScoreStatus.APPROVED.value,
                    typeId:typeId
                }
            }).then((data)=>{
                pageData.value=data;
            }).catch(()=>{
                Message({type:"error",text:"网络请求失败，请稍后再试"});
            })
        }
        getPageScoreList(1,null);

        const searchPageScoreList=(text,pageNum)=>{
            axios.post("/score/searchPageScoreList",{
                pageNum:pageNum,
                pageSize:8,
                model:"%"+text+"%"
            }).then((data)=>{
                tag.value='search';
                pageData.value=data;
            }).catch(()=>{
                Message({type:"error",text:"网络请求失败，请稍后再试"});
            })
        }
        // queryScoreResPage(8,1,{power:'公开',status:'审核通过'}).then((res)=>{
        //     console.log(res.data);
        //     //scs.value=res.data.data;
        //     pageData.value=res.data;
        // });
        const tag=ref('query');
        const change=()=>{
            // console.log(pageData.pageNum);
            if(tag.value=='query'){
                // queryScoreResPage(8,pageData.value.pageNum,{power:'公开',status:'审核通过'}).then((res)=>{
                //     console.log(res.data);
                //     //scs.value=res.data.data;
                //     pageData.value=res.data
                // });
                getPageScoreList(pageData.value.pageNum,null);
            }else if(tag.value=='search'){
                searchPageScoreList(text.value,1);
                // searchScoreResPage(8,pageData.value.pageNum,{power:'公开',status:'审核通过'},text.value).then((res)=>{
                //     pageData.value=res.data;
                // }).catch(()=>{
                //     Message({type:'err',text:'服务器启动中，请稍后再试'});
                // })
            }
        }
        const text=ref('');
        const searchScore=()=>{
            searchPageScoreList(text.value,1);
            // searchScoreResPage(8,1,{power:'公开',status:'审核通过'},text.value).then((res)=>{
            //     pageData.value=res.data;
            //     tag.value='search';
            // }).catch(()=>{
            //     Message({type:'err',text:'服务器启动中，请稍后再试'});
            // })
        }
        const typeId=ref(null);
        const classify=()=>{
            // queryScoreResPage(8,1,{power:'公开',status:'审核通过',typeId:typeId.value}).then((res)=>{
            //     pageData.value=res.data
            // });
            //console.log("class");
            //console.log(typeId.value);
            getPageScoreList(1,typeId.value);
        }

        return {pageData,change,text,searchScore,typeId,classify};
    }
}
</script>

<style lang="less" scoped>
.scoreFound{
    margin-top:30px;
    .top{
        margin: auto;
        width: 1200px;
        padding: 30px;
        background-color: white;
        margin-bottom: 10px;
        display: flex;
        .search{
            margin-left: 400px;
        }
        .classify{
            margin-left: 250px;
        }
    }
    .pc{
        margin: auto;
        margin-top: 10px;
        width:1200px;
        padding: 20px;
        background-color: white;
        .ctl{
            margin: auto;
        }
    }
}
i{
    font-size: 30px;
}
</style>
