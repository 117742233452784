<template>
  <div class="content">
    <input type="text" v-model="text" placeholder="乐曲/作者/上传者"/><div class="btn" @click="search"><i class="iconfont icon-search">&nbsp;</i>搜索</div>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
export default {
    name:'Search',
    props:{
        modelValue:{
            type:String,
            default:''
        }
    },
    setup(props,{emit}){
        const text=useVModel(props,'modelValue',emit);
        const search=()=>{
            emit('search');
        }
        return {text,search};
    }
}
</script>

<style lang="less" scoped>
.content{
    display: flex;
    //border: solid;
}
input{
    width: 250px;
    border: solid;
    height: 40px;
    border-radius: 5px 0 0 5px;
    padding-left: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.btn{
    width:85px;
    height: 40px;
    border: solid black;
    text-align: center;
    line-height: 40px;
    background-color: black;
    color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    font-size: 17px;
    &:hover{
        cursor: pointer;
    }
}
</style>