<template>
  <div class="pageController">
    <PButton @click="change(-1)" :hoverActive="false" :active="data.pageNum>1">上一页</PButton><span>{{data.pageNum}}/{{totalPageNum}}</span><PButton @click="change(1)" :hoverActive="false" :active="data.pageNum<totalPageNum">下一页</PButton>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import {computed} from 'vue'
export default {
    name:'PageController',
    props:{
        modelValue:{
            type:Object,
            default:()=>{
                return {pageNum:1,pageSize:5}
            }
        }
    },
    setup(props,{emit}){
        // const vPageNum=useVModel(props,'pageNum',emit);
        // //const vTotalNum=useVModel(props,'totalNum',emit);
        // const vTotalNum=ref(5);
        const data=useVModel(props,'modelValue',emit);
        const totalPageNum=computed(()=>{
            return Math.ceil(parseFloat(data.value.totalNum)/data.value.pageSize);
        })
        const change=(num)=>{
            if(num==1){
                if(data.value.pageNum<totalPageNum.value){
                    data.value.pageNum++;
                    emit('change');
                }
            }else{
                if(data.value.pageNum>1){
                    data.value.pageNum--;
                    emit('change');
                }
            }
        }
        
        return {change,data,totalPageNum}
    }
}
</script>

<style lang="less" scoped>
.pageController{
    display: flex;
    justify-content : space-between;
    width: 350px;
    //border: solid;
    span{
        font-size: 25px;
    }
}
</style>