<template>
  <div>
    <span>分类：</span>
    <select v-model="typeId">
        <template v-if="type">
            <option :value='null'>全部</option>
            <option v-for="t,i of type" :key="i" :value="t.id" @change="change">{{t.type}}</option>
        </template>
    </select>
  </div>
</template>

<script>
//import {getScoreType} from '@/api/score'
import { useVModel } from '@vueuse/core'
import {ref} from 'vue';
import axios from '../../../utils/request';
import Message from '../../../components/library/Message';
export default {
    name:'Classify',
    props:{
        modelValue:{
            type:Number,
            default:null
        }
    },
    setup(props,{emit}){
        const typeId=useVModel(props,'modelValue',emit);
        let type=ref(null);
        // getScoreType().then((res)=>{
        //     type.value=res.data;
        // })
        axios.get("/scoreType/getScoreTypeList").then((data)=>{
            type.value=data;
        }).catch((err)=>{
            Message({type:"error",text:err.message});
        })
        const change=()=>{
            emit('change');
        }
        return {typeId,type,change};
    }
}
</script>

<style lang="less" scoped>

</style>